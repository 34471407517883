import Quill from 'quill';

const Code = Quill.import('formats/code');

class formatParam extends Code {
    static create() {
        let node = super.create();
        node.setAttribute('style', "-webkit-text-emphasis: dot;text-emphasis: dot ;text-emphasis-position: under left;-webkit-text-emphasis-position: under left;background: none;");
        return node;
    }

    static value(domNode) {
        return domNode.dataset.id;
    }
}

formatParam.blotName = 'span';
formatParam.tagName = 'span';

export default formatParam;