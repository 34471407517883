<template>
    <div >
        <quill-editor
                @ready="onEditorReady($event)"
                v-model="info"
                ref="myQuillEditor"
                :options="editorOption"
                @blur="onEditorBlur($event)" @focus="onEditorFocus($event)"
                style="width:100%;"
                :onchange="onEditorChange"
                @change="onEditorChange($event)"
        >
        </quill-editor>

        <el-button class="emphasis" style="display: none" type="primary">主要按钮</el-button>

        <div style="display: none"><cover ref="image" :uploadNumber="100" :uploadPath="uploadPath" @success="uploadSuccess"></cover></div>
    </div>
</template>

<script>
    import { Quill,quillEditor } from "vue-quill-editor"; //调用编辑器
    import formatParam from './formatParam';
    import 'quill/dist/quill.core.css';
    import 'quill/dist/quill.snow.css';
    import 'quill/dist/quill.bubble.css';
    import ImageUpload from "../imageUpload";
    import Cover from "../cover";
    import {config} from "../../js/config";
    export default {
        name: "miniEditor",
        props:[
            'content'
        ],
        components:{
            Cover,
            quillEditor
        },
        data(){
            return {
                uploadPath:'editor',
                editorOption: {
                    placeholder: "请在这里输入",
                    height:'120px',
                    modules:{
                        toolbar:{
                            container:[
                                ['bold', 'italic', 'underline', 'strike'],    //加粗，斜体，下划线，删除线
                                [{ 'color': [] }, { 'background': [] }],     // 字体颜色，字体背景颜色
                                ['clean'],    //清除字体样式
                                [{ 'align': [] }],    //对齐方式
                                ['emphasis'],
                            ],
                            handlers:{
                                'emphasis' : () => {
                                    let range = this.$refs.myQuillEditor.quill.getSelection(true)
                                    let delta = this.$refs.myQuillEditor.quill.getContents(range.index,range.length)
                                    this.$refs.myQuillEditor.quill.formatText(range.index,range.length,{'span':true})
                                    console.log(delta)
                                }
                            }
                        }

                    },
                    initButton:function(){
                        const emphasisEditorButton = document.querySelector('.ql-emphasis');
                        emphasisEditorButton.className = "el-icon-more";
                        emphasisEditorButton.title = "着重点";
                    }
                },
                formats:['formatParam'],
                info: this.content
            };
        },
        watch: {
            // content(newVal){
            // 	this.info = newVal
            // }
        },
        methods:{
            onEditorReady(editor) { // 准备编辑器
                console.log(editor.container.querySelector(".ql-editor").innerHTML = this.info)
            },
            onEditorBlur(e){

            }, // 失去焦点事件
            onEditorFocus(e){
            }, // 获得焦点事件
            onEditorChange(e){
                this.$emit('onChange',e.html)
            }, // 内容改变事件
            uploadSuccess(pic){
                let image = '<p><img src="http:'+config.imageUrl + pic+'" width="100%" /></p>'
                this.content =  this.content+image
                console.log(this.content+image)
                console.log(config.imageUrl + pic)
            }
        },
        computed: {
            editor() {
                return this.$refs.myQuillEditor.quill;
            }
        },
        mounted() {


            this.$nextTick(() => {
                this.editorOption.initButton()
            })
            Quill.register(formatParam)
            // Quill.debug('info')
        }
    }
</script>

<style scoped>
    .aaaa{
        text-emphasis: filled  ;
        text-emphasis-position: under left;

    }
</style>
